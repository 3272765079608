import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { IsValidApiRsps } from '../utils/valid';

const useListOfPv = () => {
  return useQuery({
    queryKey: ['query-object-list-of-tg'],
    queryFn: async () => {
      return axios.post('/api/object/list', { type: '1' }).then((res) => {
        if (IsValidApiRsps(res)) {
          return res.data.data.map((m, idx) => ({
            key: idx,
            label: m.objectID + '-' + m.name,
            value: m.objectID,
          }));
        }
        return [];
      });
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    cacheTime: 1000 * 60 * 60 * 2, // 2 hours
  });
};

export default useListOfPv;
