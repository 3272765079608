import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
//
import appConfigs from '../configs';
//
import AppLayout from './appLayout';
import Login from '../pages/Login';

const App = () => {
  useEffect(() => {
    if (appConfigs.stageName) {
      document.title = `微電能源股份有限公司 - 即時監控系統 - ${appConfigs.stageName}`;
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login homePageUrl="/home" />} />
      <Route path="*" element={<AppLayout />} />
    </Routes>
  );
};

export default App;
