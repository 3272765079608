import moment from 'moment';
import { IsNumber } from './valid';

export const AppSyncQueryTextFormat = (queryText) => {
  return (queryText || '').replaceAll('  ', '').replaceAll('\n', ' ');
};

export const NumberFormat = (value, digits = 2, prefix, postfix) => {
  if (IsNumber(value) || value === 0) {
    let content = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: digits,
    });

    return `${prefix || ''}${content} ${postfix || ''}`.trimEnd();
  } else {
    return '--';
  }
};

export const DateFormat = (value) => {
  if (!value) return null;
  if (moment.isMoment(value)) return value.format('YYYY-MM-DD');
  return moment(value).format('YYYY-MM-DD');
};

export const DateTimeFormat = (value) => {
  if (!value) return null;
  if (moment.isMoment(value)) return value.format('YYYY-MM-DD HH:mm:ss');
  return moment(value).format('YYYY-MM-DD HH:mm:ss');
};

export const FormObjectToJsonString = (value, momentColNames) => {
  if (!value) return '';
  if (typeof value !== 'object') return '';
  if (Array.isArray(value)) return '';

  const objectData = { ...value };
  const keys = Object.keys(objectData);
  if (!keys || keys.length === 0) return '';

  // empty array is null.
  for (let i = 0; i < keys.length; i++) {
    if (Array.isArray(objectData[keys[i]]) && objectData[keys[i]].length === 0) {
      delete objectData[keys[i]];
    }
  }

  // format moment type field.
  if (Array.isArray(momentColNames) && momentColNames.length > 0) {
    momentColNames.forEach((mcn) => {
      if (objectData[mcn]) {
        const mmt = moment(objectData[mcn]);
        if (mmt.isValid()) {
          objectData[mcn] = mmt.format('YYYY-MM-DD HH:mm:ss');
        }
      }
    });
  }

  return JSON.stringify(objectData);
};

export const JsonStringToFormObject = (jsonString, momentColNames) => {
  if (!jsonString) return null;

  const objectData = JSON.parse(jsonString);

  if (Array.isArray(momentColNames) && momentColNames.length > 0) {
    momentColNames.forEach((mc) => {
      if (objectData[mc]) {
        const mmt = moment(objectData[mc]);
        if (mmt.isValid()) {
          objectData[mc] = mmt;
        }
      }
    });
  }

  return objectData;
};

export const SetKeyToArray = (item, itemIndex) => {
  item.key = itemIndex;
  return item;
};
