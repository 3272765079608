import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
//
import appConfigs from '../../configs';
import { NumberFormat } from '../../utils/format';

const RfdmeChart = ({ labels = { x: 'HR', y: 'KWH' }, data = [], title, name, chartType = 'sola' }) => {
  const timezone = parseInt(appConfigs.timezone);
  const today = moment().format('YYYY-MM-DD');
  const series = [
    {
      name: name || title,
      data: (data || []).map((d) => {
        return {
          x: moment(`${today} ${d.time}`).utcOffset(timezone).valueOf(),
          y: d.value,
        };
      }),
    },
  ];
  const options = {
    chart: {
      type: 'line',
      stacked: false,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: false,
      },
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: 'zoom',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
      colors: '#2871a2',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    title: {
      text: title,
      align: 'center',
      margin: 10,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        showDuplicates: false,
        datetimeUTC: false,
        format: 'HH',
      },
      title: {
        text: labels.x,
      },
      min:
        chartType === 'load'
          ? moment(`${today} 00:00`).utcOffset(timezone).valueOf()
          : moment(`${today} 05:00`).utcOffset(timezone).valueOf(),
      max: moment(`${today} 23:45`).utcOffset(timezone).valueOf(),
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return NumberFormat(val);
        },
      },
      title: {
        text: labels.y,
      },
    },
    tooltip: {
      x: {
        show: true,
        format: 'HH:mm',
      },
      y: {
        formatter: (val) => {
          return NumberFormat(val);
        },
      },
    },
    // noData: {
    //   text: 'Loading...',
    // },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="line" width="95%" height="95%" />
    </div>
  );
};

export default RfdmeChart;
