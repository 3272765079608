import React, { useState } from 'react';
import {
  GetEncryptedString,
  GetDecryptedString,
  GetEncryptObject,
  GetDecryptedObject,
} from '../utils/encryption';

//Auth 共享文本
const AuthContext = React.createContext();

//
const GetInitUser = () => {
  const userName = window.localStorage.getItem('userName');
  const menus = GetDecryptedObject(window.localStorage.getItem('menus'));
  const token = GetDecryptedString(window.localStorage.getItem('token'));

  if (token) {
    return {
      userName: userName,
      menus: menus || [],
      token: token,
    };
  } else {
    return null;
  }
};

export const SessionClear = () => {
  window.localStorage.removeItem('userName');
  window.localStorage.removeItem('menus');
  window.localStorage.removeItem('token');
};

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState(GetInitUser());

  const AuthUser = (authUser) => {
    if (authUser) {
      authUser.token = `Bearer ${authUser.token}`;

      window.localStorage.setItem('userName', authUser.userName);
      window.localStorage.setItem('menus', GetEncryptObject(authUser.menus));
      window.localStorage.setItem('token', GetEncryptedString(authUser.token));
      setUser(authUser);
    }
  };
  const UnAuthUser = () => {
    SessionClear();
    setUser(null);
  };

  return <AuthContext.Provider value={{ user, AuthUser, UnAuthUser }}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
