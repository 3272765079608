import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Row, Col, Layout } from 'antd';
import moment from 'moment';
import axios from 'axios';
//
import SelectComponent from '../../components/Select';
import ChartComponent from '../../components/Chart';
//
import LogoImage from '../../images/mylandingpage/logo_rfdme.svg';
import BackgroundImage from '../../images/mylandingpage/view.png';
import TempIcon from '../../images/mylandingpage/temp.svg';
import SunIcon from '../../images/mylandingpage/sun.svg';
import RainIcon from '../../images/mylandingpage/rain.svg';
import WindIcon from '../../images/mylandingpage/wind.svg';
import Co2Icon from '../../images/mylandingpage/co2.svg';
import TreeIcon from '../../images/mylandingpage/tree.svg';
//
import appConfigs from '../../configs';
import AuthContext from '../../contexts/authProvider';
import { IsValidApiRsps } from '../../utils/valid';
import { NumberFormat } from '../../utils/format';

const { Header, Content, Footer } = Layout;

const REFRESH_INTERVAL = parseInt(appConfigs.refreshMinutes) * 60000;

const Dashboard = ({ objectList, metricsTypeList, generationType, backgroundImage }) => {
  const { user } = useContext(AuthContext);
  const [objectData, setObjectData] = useState(null);
  const [metrics, setMetrics] = useState({});
  const [generation, setGeneration] = useState({});
  const [currentTime, setCurrentTime] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [shouldFetchData, setShouldFetchData] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = moment();
      const formattedNow = now.format('YYYY-MM-DD HH:mm');
      if (now.seconds() === 0 || currentTime !== formattedNow) {
        setCurrentTime(formattedNow);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [currentTime]);

  useEffect(() => {
    if (!objectData && objectList && objectList.length > 0) {
      setObjectData(objectList[0]);
      setShouldFetchData(true);
    }
  }, [objectList, objectData]);

  const fetchMetrics = useCallback(() => {
    const postData = {
      typeList: metricsTypeList,
      objectID: objectData.value,
      timezone: '8',
    };

    if (user) {
      return axios.post(`/api/object/metrics`, postData, { headers: { Authorization: user.token } });
    } else {
      return axios.post(`/api/object/metrics`, postData);
    }
  }, [user, objectData, metricsTypeList]);

  const fetchGenerationData = useCallback(() => {
    const postData = { type: generationType, objectID: objectData.value, timezone: '8' };

    if (user) {
      return axios.post(`/api/object/generation`, postData, { headers: { Authorization: user.token } });
    } else {
      return axios.post(`/api/object/generation`, postData);
    }
  }, [user, objectData, generationType]);

  const fetchData = useCallback(async () => {
    if (!objectData) return;

    const metricsPromise = fetchMetrics();
    const generationPromise = fetchGenerationData();
    await axios
      .all([metricsPromise, generationPromise])
      .then(
        axios.spread((metricsResponse, generationResponse) => {
          if (IsValidApiRsps(metricsResponse) && IsValidApiRsps(generationResponse)) {
            setMetrics(metricsResponse.data.data);
            setGeneration(generationResponse.data.data);
          }
        })
      )
      .catch((err) => {
        console.error('API call failed:', err);
      });
  }, [objectData, fetchMetrics, fetchGenerationData]);

  useEffect(() => {
    if (shouldFetchData) {
      fetchData();
      setShouldFetchData(false);
    }

    const intervalId = setInterval(fetchData, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [shouldFetchData, fetchData]);

  const handleObjectDataChang = (newObjectId) => {
    const newSelectedObject = objectList.find((site) => site.value === newObjectId);
    if (newSelectedObject) {
      setObjectData(newSelectedObject);
      setShouldFetchData(true);
    }
  };

  const convertTimeTo12HFormat = (timeStr) => {
    if (!timeStr) return '';
    // 使用冒號分割時間
    let [hours, minutes] = timeStr.split(':');
    // 轉換小時為整數型別
    hours = parseInt(hours);
    // 判斷是否為下午
    const isPM = hours >= 12;
    // 如果是下午，則小時數減去12 (除非是12點，因為12:00 PM是正確的)
    if (isPM && hours > 12) {
      hours -= 12;
    }
    // 將小時轉換回為雙位數的字串
    const hoursStr = hours.toString().padStart(2, '0');
    // 決定是AM還是PM
    const amPm = isPM ? 'PM' : 'AM';
    // 返回結果
    return `${hoursStr}:${minutes} ${amPm}`;
  };

  return (
    <>
      <Header className="app-page-header">
        <Row>
          <Col className="app-page-pic">
            <img src={backgroundImage ? backgroundImage : BackgroundImage} alt="rfdme-pic" />
          </Col>
          <Col className="app-page-logo">
            <img src={LogoImage} alt="rfdme-logo" className="rfdme-logo" />
            <div className="app-page-select-wrap">
              <SelectComponent
                showArrow={false}
                options={objectList}
                value={objectData}
                onChange={handleObjectDataChang}
                listHeight={512}
              />
            </div>
          </Col>
        </Row>
      </Header>
      <Content className="app-page-content">
        <div className="app-page-card-wrap">
          <Row gutter={24} style={{ width: '100%' }}>
            {metricsTypeList && metricsTypeList.find((t) => t === 'temperature') && (
              <Col span={6}>
                <div className="app-page-card">
                  <div className="cover">
                    <img src={TempIcon} alt="rfdme-temp" />
                    <span className="num">{NumberFormat(metrics.temperature, 1)}</span>
                    <span className="unit">°C</span>
                  </div>
                  <p>{currentTime}</p>
                </div>
              </Col>
            )}
            {metricsTypeList && metricsTypeList.find((t) => t === 'irr') && (
              <Col span={6}>
                <div className="app-page-card">
                  <div className="cover">
                    <img src={SunIcon} alt="rfdme-sun" />
                    <span className="num">{NumberFormat(metrics.irr, 1)}</span>
                    <span className="unit">W/M²</span>
                  </div>
                  <p>即時日照</p>
                </div>
              </Col>
            )}
            {metricsTypeList && metricsTypeList.find((t) => t === 'rainfall') && (
              <Col span={6}>
                <div className="app-page-card">
                  <div className="cover">
                    <img src={RainIcon} alt="rfdme-rain" />
                    <span className="num">{NumberFormat(metrics.rainfall, 1)}</span>
                    <span className="unit">%</span>
                  </div>
                  <p>降雨機率預報</p>
                </div>
              </Col>
            )}
            {metricsTypeList && metricsTypeList.find((t) => t === 'windSpeed') && (
              <Col span={6}>
                <div className="app-page-card">
                  <div className="cover">
                    <img src={WindIcon} alt="rfdme-wind" />
                    <span className="num">{NumberFormat(metrics.windSpeed, 1)}</span>
                    <span className="unit">M/S</span>
                  </div>
                  <p>風速預報</p>
                </div>
              </Col>
            )}
            {metricsTypeList && metricsTypeList.find((t) => t === 'co2') && (
              <Col span={6}>
                <div className="app-page-card">
                  <div className="cover">
                    <img src={Co2Icon} alt="rfdme-co2" />
                    <span className="num">{NumberFormat(metrics.co2, 1)}</span>
                    <span className="unit">公噸</span>
                  </div>
                  <p>CO2減排量</p>
                </div>
              </Col>
            )}
            {metricsTypeList && metricsTypeList.find((t) => t === 'plantTree') && (
              <Col span={6}>
                <div className="app-page-card">
                  <div className="cover">
                    <img src={TreeIcon} alt="rfdme-tree" />
                    <span className="num">{NumberFormat(metrics.plantTree, 1)}</span>
                    <span className="unit">千顆</span>
                  </div>
                  <p>約當造林數</p>
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div className="app-page-data-wrap">
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">總容量</div>
                <span className="num">{NumberFormat(generation.capacity, 2)}</span>
                <span className="unit">KWP</span>
              </div>
            </Col>
            {generationType === 'kwh' && (
              <Col span={8}>
                <div className="app-page-data">
                  <div className="title">即時發電量</div>
                  <span className="num">
                    <span className="focus">{NumberFormat(generation.nowPower, 2)}</span>
                  </span>
                  <span className="unit">KWH</span>
                </div>
              </Col>
            )}
            {generationType === 'kw' && (
              <Col span={8}>
                <div className="app-page-data">
                  <div className="title">即時發電功率</div>
                  <span className="num">
                    <span className="focus">{NumberFormat(generation.nowPower, 2)}</span>
                  </span>
                  <span className="unit">KW</span>
                </div>
              </Col>
            )}
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">等效日照小時</div>
                <span className="num">{NumberFormat(generation.sunshine, 2)}</span>
                <span className="unit">HR</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">總累積發電量</div>
                <span className="num">{NumberFormat(generation.totalPower, 2)}</span>
                <span className="unit">KWH</span>
              </div>
            </Col>
            {generationType === 'kwh' && (
              <Col span={8}>
                <div className="app-page-data">
                  <div className="title">今日高峰發電量</div>
                  <h5 className="triggertime">
                    發生時間 {convertTimeTo12HFormat(generation.todayMaxPowerTime)}
                  </h5>
                  <span className="num">
                    <span className="focus">{NumberFormat(generation.todayMaxPower, 2)}</span>
                  </span>
                  <span className="unit">KWH</span>
                </div>
              </Col>
            )}
            {generationType === 'kw' && (
              <Col span={8}>
                <div className="app-page-data">
                  <div className="title">今日高峰發電</div>
                  <div className="triggertime">
                    發生時間 {convertTimeTo12HFormat(generation.todayMaxPowerTime)}
                  </div>
                  <span className="num">
                    <span className="focus">{NumberFormat(generation.todayMaxPower, 2)}</span>
                  </span>
                  <span className="unit">KW</span>
                </div>
              </Col>
            )}
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">今日累積發電量</div>
                <span className="num">{NumberFormat(generation.todaySumPower, 2)}</span>
                <span className="unit">KWH</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="app-page-chart-wrap">
          <div className="app-page-chart">
            {generationType === 'kwh' && (
              <ChartComponent
                labels={{ x: 'HR', y: 'KWH' }}
                title="今日累積發電量"
                name="累積發電量"
                data={generation.chartData}
              />
            )}
            {generationType === 'kw' && (
              <ChartComponent
                labels={{ x: 'HR', y: 'KW' }}
                title="及時發電功率"
                name="發電功率"
                data={generation.chartData}
              />
            )}
          </div>
        </div>
      </Content>
      <Footer className="app-page-footer"></Footer>
    </>
  );
};

export default Dashboard;
