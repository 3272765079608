import { Select } from 'antd';
import { handleAntSelectSearch } from '../../utils/antAction';
import styled from 'styled-components';
//
import LocationIcon from '../../images/mylandingpage/icon_rfdme_solar_pv_system_display_location.svg';

const RfdmeSelect = ({ label, ...props }) => {
  const showSearch = props.showSearch || false;
  const filterOption = showSearch ? handleAntSelectSearch : null;

  return (
    <SelectWrapper>
      <div className="prefix-icon-wrapper">
        <img src={LocationIcon} alt="prefix-icon" />
      </div>
      <Select
        style={{ width: '100%' }}
        showArrow={false}
        placeholder={label && `請選擇${label}`}
        showSearch={showSearch}
        filterOption={filterOption}
        {...props}
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 3px);
    border-radius: 200px;
    font-size: 1.8rem;
    font-weight: bold;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  && .ant-select-selection-item {
    font-weight: bold;
  }
`;

export default RfdmeSelect;
