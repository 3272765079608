import React from 'react';
import Dashboard from '../../components/Dashboard';
//
import useObjectOfPerfectPac from '../../hooks/useObjectOfPerfectPac';

const PerfectPac = () => {
  const { data: objectList } = useObjectOfPerfectPac();
  if (!objectList || objectList.length === 0) {
    return <></>;
  }

  return (
    <Dashboard
      objectList={objectList}
      metricsTypeList={['temperature', 'irr', 'co2', 'plantTree']}
      generationType={'kw'}
    />
  );
};

export default PerfectPac;
