import React from 'react';
import RfdmePic from '../../images/mylandingpage/map/img_t7422_monitor.jpg';
import Dashboard from '../../components/Dashboard';
//
import useObjectOfTianG from '../../hooks/useObjectOfTianG';

const TianG = () => {
  const { data: objectList } = useObjectOfTianG();

  if (!objectList || objectList.length === 0) {
    return <></>;
  }

  return (
    <Dashboard
      objectList={objectList}
      metricsTypeList={['temperature', 'irr', 'rainfall', 'windSpeed']}
      generationType={'kwh'}
      backgroundImage={RfdmePic}
    />
  );
};

export default TianG;
