import React from 'react';
//
import useObjectOfHsiTa from '../../hooks/useObjectOfHsiTa';
import Dashboard from '../../components/Dashboard';

const HsiTa = () => {
  const { data: objectList } = useObjectOfHsiTa();

  if (!objectList || objectList.length === 0) {
    return <></>;
  }

  return (
    <Dashboard
      objectList={objectList}
      metricsTypeList={['temperature', 'irr', 'rainfall', 'windSpeed']}
      generationType={'kwh'}
    />
  );
};

export default HsiTa;
