import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
//
import Home from '../pages/Home';
import Miaoli from '../pages/Miaoli';
import TianG from '../pages/TianG';
import ULong from '../pages/ULong';
import PerfectPac from '../pages/PerfectPac';
import HsiTa from '../pages/HsiTa';
import PageNotFound from '../pages/PageNotFound';
import PrivateRoute from '../components/PrivateRoute';

const { Content } = Layout;

const AppLayout = () => {
  return (
    <Layout className="app-layout">
      <Layout className="app-layout">
        <Content className="app-content">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/miaoli" element={<Miaoli />} />
            <Route
              path="/tiang"
              element={
                <PrivateRoute>
                  <TianG />
                </PrivateRoute>
              }
            />
            <Route path="/ulong" element={<ULong />} />
            <Route path="/perfectpac" element={<PerfectPac />} />
            <Route path="*" element={<PageNotFound />} />
            <Route
              path="/hsita"
              element={
                <PrivateRoute>
                  <HsiTa />
                </PrivateRoute>
              }
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
