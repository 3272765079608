import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
//
import { Row, Col, Input, message, Button, Modal } from 'antd';
import { ReactComponent as RfdmeLogo } from '../../images/login/logo_home_rfdme.svg';
import BackgroundPic from '../../images/login/bg_home_banner.png';
import RfdmeTitle from '../../images/login/title_banner_rfdme_tc.svg';
//
import appConfigs from '../../configs';
import { IsValidApiGatewayRsps, IsEmail } from '../../utils/valid';
import AuthContext from '../../contexts/authProvider';

//
const { Password } = Input;
//
const LeftDiv = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Times New Roman', Times, serif;
`;
const TitleDiv = styled.div`
  margin-left: 15%;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`;
const DescParagraph = styled.p`
  margin-top: 20px;
  color: white;
  font-size: 28px;
  line-height: 40px;
  width: 60%;
  padding-left: 10px;
`;
const RightDiv = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
const Page = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 55px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
const FooterParagraph = styled.p`
  color: white;
  font-size: 12px;
`;
const LoginForm = styled.form`
  width: 300px;

  margin-top: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .dark-input {
    width: 360px;
    height: 40px;
    background-color: #f4f4f4;
    border-radius: 20px;
    border: none;
    text-align: left;
    caret-color: #707070;
    font-size: 18px;
    font-weight: 500;
    box-sizing: border-box;
    font-family: 'Noto Sans TC', '微軟正黑體';
    width: 360px;
    height: 40px;
    background-color: #f4f4f4;
    border-radius: 20px;
    border: none;
    padding-left: 25px;
  }
  .dark-input > input,
  .dark-input > span,
  .dark-input .anticon-eye,
  .dark-input .anticon-eye-invisible {
    background: transparent;
  }
`;

//
const Login = ({ homePagePath }) => {
  const { AuthUser } = useContext(AuthContext);
  const [loginData, setLoginData] = useState({ account: '', pwd: '' });
  const [loading, setLoading] = useState(false);
  //
  const [accountForget, setAccountForget] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [sending, setSending] = useState(false);
  //
  const navigate = useNavigate();

  const handleInputChange = (name) => (e) => {
    setLoginData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleLogin = () => {
    if (!loginData.account) {
      message.warning('請輸入帳號');
      return;
    } else if (!IsEmail(loginData.account)) {
      message.warning('帳號格式異常, 請重新輸入與確認');
      return;
    }
    if (!loginData.pwd) {
      message.warning('請輸入密碼');
      return;
    }

    setLoading(true);
    axios
      .post(`${appConfigs.apiGatewayURL}/auth/login`, {
        systemID: appConfigs.systemID,
        account: loginData.account.trim(),
        password: loginData.pwd.trim(),
      })
      .then((res) => {
        if (!IsValidApiGatewayRsps(res)) {
          message.error('登入失敗，請重新輸入帳號與密碼');
          console.warn('login fail', res);
          return;
        }

        if (!res.data.data.token) {
          message.error('登入失敗，無法取得授權金鑰');
          console.warn('login fail', res);
          return;
        }

        AuthUser({
          userName: res.data.data.userName,
          menus: res.data.data.menuList || [],
          operations: res.data.data.operationList || [],
          token: res.data.data.token,
        });

        if (Array.isArray(res.data.data.menuList) && res.data.data.menuList.length) {
          const menu = res.data.data.menuList[0];
          navigate(menu.menuPath);
        } else {
          navigate(homePagePath);
        }
      })
      .catch((err) => {
        message.error('登入異常');
        console.error('login error', err);
      })
      .then(() => {
        setLoading(false);
      });
  };

  //=======================================================
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setAccountForget('');
    setModalOpen(false);
  };
  const handleAccountForgetChange = (e) => {
    setAccountForget(e.target.value);
  };
  //
  const handleForgetPwd = async () => {
    if (!accountForget) {
      message.warning('請輸入帳號');
      return;
    } else if (!IsEmail(accountForget)) {
      message.warning('帳號格式異常, 請重新輸入與確認');
      return;
    }

    setSending(true);
    await axios
      .post(`${appConfigs.apiGatewayURL}/auth/user/forgetpwd`, {
        systemID: appConfigs.systemID,
        email: accountForget,
      })
      .then((res) => {
        if (res && res.data && res.data.status === true) {
          message.success('請至您的信箱確認忘記密碼信件');
          handleModalClose();
        } else {
          const msg = res.data.msg;
          console.warn(`forgetPwd fail: ${msg}`);
          message.warn(msg);
        }
      })
      .catch((err) => {
        console.error(`forgetPwd fail: ${err}`);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <Page>
      <Content>
        <LeftDiv style={{ backgroundImage: `url(${BackgroundPic})` }}>
          <TitleDiv>
            <img alt="rfdme" src={RfdmeTitle} />
            <DescParagraph>Welcome to the RFD Micro Electricity Investor Area</DescParagraph>
          </TitleDiv>
          <Footer>
            <FooterParagraph>
              © {moment().year()} 微電能源股份有限公司 RFD Micro Electricity Co., Ltd. All rights reserved.
            </FooterParagraph>
          </Footer>
        </LeftDiv>
        <RightDiv>
          <LoginForm>
            <RfdmeLogo style={{ marginBottom: '128px' }} />
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Input
                  className="dark-input"
                  placeholder="Account"
                  value={loginData.account}
                  onChange={handleInputChange('account')}
                />
              </Col>
              <Col span={24}>
                <Password
                  className="dark-input"
                  placeholder="Password"
                  value={loginData.pwd}
                  onChange={handleInputChange('pwd')}
                  onPressEnter={handleLogin}
                  autoComplete="off"
                />
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Col span={6}>
                    <Button
                      type="link"
                      style={{ color: '#2185D0', fontSize: '16px' }}
                      onClick={handleModalOpen}
                    >
                      忘記密碼?
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Button block id="login-button" loading={loading} onClick={handleLogin}>
                  登入
                </Button>
              </Col>
            </Row>
          </LoginForm>
        </RightDiv>
      </Content>

      <Modal
        open={modalOpen}
        title="重設密碼驗證"
        onOk={handleForgetPwd}
        onCancel={handleModalClose}
        footer={[
          <Button key="back" onClick={handleModalClose} disabled={sending}>
            關閉
          </Button>,
          <Button
            key="submit"
            style={{ color: '#fff', backgroundColor: '#286090', borderColor: '#204d74' }}
            onClick={handleForgetPwd}
            loading={sending}
          >
            送出
          </Button>,
        ]}
        centered
        width={600}
      >
        <Row gutter={[0, 16]} style={{ fontSize: '16px' }}>
          <Col span={24}>請輸入您的帳號（電子郵件地址）：</Col>
          <Col span={24}>
            <Input
              size="large"
              type="email"
              placeholder="帳號（電子郵件）"
              value={accountForget}
              onChange={handleAccountForgetChange}
            />
          </Col>
          <Col>
            <div>帳號驗證無誤後,系統將會郵寄確認信件到您的電子郵件信箱,</div>
            <div>請收取信件後,依郵件內容指示操作,謝謝！</div>
          </Col>
        </Row>
      </Modal>
    </Page>
  );
};

export default Login;
