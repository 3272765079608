import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { message } from 'antd';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider, SessionClear } from './contexts/authProvider';
//
import App from './layout/app';
//import reportWebVitals from './reportWebVitals';
//
import 'antd/dist/antd.min.css';
import './css/main.css';

axios.interceptors.request.use(
  (config) => {
    const tokenInHeader = (((config || { headers: {} }).headers || {}).Authorization || '')
      .replace('Bearer', '')
      .trim();
    if (!tokenInHeader) return config;

    const tokenInStorage = window.localStorage.getItem('token');
    if (!tokenInStorage) return Promise.reject('token was removed');

    const expTimestamp = (jwtDecode(tokenInHeader) || {}).exp || 1;
    if (!moment().isSameOrBefore(expTimestamp * 1000)) {
      message.warn({
        content: (
          <div>
            <strong>登入逾時</strong>
            <div>系統將於 3 秒後自動登出，請再次登入</div>
          </div>
        ),
      });

      //
      SessionClear();
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000 * 3);

      return Promise.reject('token was expired');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.warn('未經授權');
          break;
        case 403:
          console.warn('沒有存取權限');
          break;
        case 404:
          console.warn('找不到該頁面');
          break;
        case 500:
          console.warn('伺服器出錯');
          break;
        case 503:
          console.warn('服務失效');
          break;
        default:
          console.warn(`連接錯誤: ${error.response.status}`);
      }
    }
    return Promise.reject(error);
  }
);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
