import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const Home = () => {
  return <Title level={2}>Welcome to Monitor-Demo system !</Title>;
};

export default Home;
