import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authProvider';

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!user || !Array.isArray(user.menus)) {
      navigate('/login');
      return;
    }

    const menu = user.menus.find((m) => m.menuPath === location.pathname);
    if (!menu) {
      navigate('/login');
      return;
    }

    setIsAuthorized(true);
  }, [user, location, navigate]);

  return isAuthorized ? children : null;
};

export default PrivateRoute;
